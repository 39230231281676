import get from '@invitato/helpers/dist/getLocale';

export default {
  understand: get('Baik, saya mengerti', 'OK, got it!'),
  title: get('Protokol Kesehatan', 'Health Protocol'),
  subTitle: get(
    `Demi kenyamanan bersama, acara pernikahan kami memberlakukan protokol kesehatan
  sebagai berikut:`,
    'For health reasons, our wedding ceremony will apply health protocols:',
  ),
  notes: get(
    `*) Catatan: <br />
    Mohon maaf, bilamana saat ini Anda dalam keadaan kurang sehat, dimohon untuk menunda
    kehadiran terlebih dahulu. Demi kenyamanan bersama, kami sangat memaklumi dan akan tetap
    menjalin silaturrahmi melalui media online. Atas perhatiannya, kami ucapkan terima
    kasih.`,
    `*) Notes: <br />
  The safety and well-being of our guests are our utmost priority. Aside from immediate family members, we respectfully request to keep this an Adult only event (children below 17 yo are encouraged to join virtually).`,
  ),
};
